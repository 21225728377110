import { render, staticRenderFns } from "./lvkang.vue?vue&type=template&id=a50d7a06&scoped=true"
import script from "./lvkang.vue?vue&type=script&lang=js"
export * from "./lvkang.vue?vue&type=script&lang=js"
import style0 from "../../../style/font/font.css?vue&type=style&index=0&id=a50d7a06&prod&scoped=true&lang=css&external"
import style1 from "./lvkang.vue?vue&type=style&index=1&id=a50d7a06&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50d7a06",
  null
  
)

export default component.exports